<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col
        cols="12" sm="5" md="5" lg="5"
        align-self="end" class="pb-1"
      >
        <span class="headline text-capitalize accent--text mt-3" v-text="$route.name"></span>
        <v-btn rounded small depressed fab color="sidebar" class="ml-2" @click="modal_alergano = true">
          <v-icon small>mdi-plus</v-icon>
        </v-btn>   
      </v-col>
      <v-col
        cols="12"
        class="pb-1"
        md="5" lg="5" sm="5"
      >
        <v-text-field
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            outlined
            rounded
            dense
            color="primary"
            placeholder="Buscar alerganos"
            v-model="search"
          ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-card outlined class="mt-0">
          <v-sheet class="" v-if="load_alergano">
            <v-responsive max-width="900" class="mx-auto">
              <v-skeleton-loader
                ref="skeleton"
                type="table"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-responsive>
          </v-sheet>      
          <v-data-table
            v-else
            :headers="headers"
            :items="alerganos"
            class="elevation-0 striped"
            :search="search"
            no-data-text="No se encontraron Alérgenos"
            no-results-text="No se encontraron Coincidencias"
            fixed-header
            item-key="id"
            loading-text="Obteniendo Alérgenos"
            v-model="selected_alergano"
          >
            <template v-slot:item.image="{item}">
                <v-avatar color="blue-grey lighten-3" size="28">
                  <!-- <v-icon dark>mdi-check</v-icon> -->
                   <img :src="route_img+item.img">
                </v-avatar>
              </template>
            <template v-slot:item.name="{ item }">
              <span class="font-weight-bold text-uppercase">{{ item.name }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-right">
                <v-btn icon color="primary lighten-1" @click="actionUpdate(item)" v-if="$can('Editar usuario')">
                  <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                </v-btn>
                 <v-btn icon color="primary lighten-1"  @click="deletealergano(item)" v-if="$can('Borrar usuario')">
                  <v-icon color="primary lighten-1">mdi-trash-can-outline</v-icon>
                </v-btn>                  
              </div>
            </template>
          </v-data-table>            
        </v-card>
      </v-col>
    </v-row>
    <!-- *******MODAL DE CREAR/EDITAR USUARIO****** -->
    <base-material-modal v-model="modal_alergano" icon="mdi-seed" :loadcard="saving_alergano" :title="action_alergano + ' Alérgeno'" @hidde="resetalergano">
      <template v-slot:content>
        <validation-observer ref="observeralergano" v-slot="">
          <v-row>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Nombre" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field outlined label="Nombre*" prepend-inner-icon="mdi-playlist-edit" v-model="formAlergano.name" :error-messages="errors[0]"></v-text-field>
              </validation-provider>
            </v-col>
             <v-col cols="12">
              <validation-provider name="Descripción" vid="description" rules="required" v-slot="{ errors }">
                 <v-textarea
                    name="Descripcion"
                    label="Descripción"
                    auto-grow
                    :error-messages="errors[0]"
                    prepend-inner-icon="mdi-playlist-edit"
                    outlined
                    placeholder=" "
                    v-model="formAlergano.description"
                  ></v-textarea>
              </validation-provider>              
            </v-col>  
          </v-row>
        </validation-observer>
      </template>
      <template v-slot:actions>
         <v-btn
            depressed 
            small
            @click="resetalergano"
            :disabled="saving_alergano"
          >
            Descartar
          </v-btn>
          <v-btn color="primary" tile small :loading="saving_alergano" @click="validatealergano">
            {{ action_alergano }}
          </v-btn>
      </template>
    </base-material-modal>

    <v-dialog v-model="load_delete" persistent width="300">
      <v-card color="info" dark>
        <v-card-text>
          <span class="white--text font-weight mb-2">
            <v-icon>mdi-trash-can-outline</v-icon>
            Eliminando restaurante
          </span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <base-material-snackbar
      v-model="snackbar.active"
      :type="snackbar.type"
      bottom
      right
    >
      {{ snackbar.text }}
    </base-material-snackbar>    
  </v-container>
</template>

<script>

  export default {
    name: 'alerganos',
    data () {
      return {
        snackbar:{
          text:'',
          active: false,
          type:''
        },
        search:'',
        selected_alergano: [],
        load_alergano: false,
        load_delete: false,
        saving_alergano: false,
        alerganos: [],
        headers: [
          {
            text: '',
            value: 'image',
            sortable: false,
            width: '60',
            align: 'center',
          },
          { text: 'Alérgeno', value: 'name' },
          { text: 'Descripción', value: 'description' },
          { text: '', value: 'actions' },
        ],
        formAlergano:{
          name: '',
          description:''
        },
        id_alergano: '',
        action_alergano: 'Crear',
        modal_alergano: false,
        route_img:''
      }
    },
    created(){
      this.getalerganos();
      this.route_img = process.env.VUE_APP_IMG_ALERGENOS;
    },
    methods: {
      async getalerganos(){
        this.load_alergano= true;
        this.alerganos = [];
        try{
          const {data} = await this.$api.get('api/allergens');
          // const objectArray = Object.entries(response.data.data.alerganos);
          // objectArray.forEach(([key, value]) => {
          //     this.b = key;
          //     this.alerganos.push(value);            
          // });

          this.alerganos = data.data.allergen;

          this.load_alergano= false;        
        } 
        catch(error){
          this.load_alergano= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener los Alérgenos',
            type: 'error',
            active:true,
          };
          
        }
      },
      resetalergano(){
        this.modal_alergano = false;
        this.id_alergano = '';
        this.action_alergano = "Crear";
        this.formAlergano.name = '';
        this.formAlergano.description = '';
         
        this.selected_alergano = [];
        this.$refs.observeralergano.reset();
      },
      validatealergano(){
        this.$refs.observeralergano.validate().then(result =>{
          if(result){
            this.actionsalergano();
          }
        });
      },
      async actionsalergano(){
        this.saving_alergano = true;
        const url = this.action_alergano == 'Crear' ? 'api/allergens' : `api/allergens/${this.id_alergano}`;
        const method = this.action_alergano == 'Crear' ? 'post' : 'put';

        try{
          const send_alergano = await this.$api({
            url: url,
            method: method,
            data: this.formAlergano
          });        
          this.resetalergano();
          this.getalerganos();
          this.saving_alergano = false;
          this.snackbar = {
            text: send_alergano.data.message,
            type: 'success',
            active: true            
          }
        }
        catch(e){
          if(e.response.status == "422"){
            this.$refs.observeralergano.setErrors(e.response.data.data);            
          }
          else{
            this.snackbar = {
              text:  e.response.data.message,
              type: 'error',
              active: true            
            }            
          }
          this.saving_alergano = false;
        }
      },
      async actionUpdate(item){
        this.id_alergano = item.id;
        this.action_alergano = 'Actualizar';
        const url = `api/allergens/${this.id_alergano}/edit`;
        try{
          const { data } = await this.$api.get(url);
          this.formAlergano = {
            name: data.data.allergen.name,
            description: data.data.allergen.description,
          } 
          this.modal_alergano = true;
        }
        catch(e){
          this.snackbar = {
            text:  e.response.data.message,
            type: 'error',
            active: true            
          }
        }
      },
      async deletealergano(item){
        const msj = "Se eliminaran permanentemente los datos del Alérgeno " + item.alerganoname;

        const confirm = await this.$root.$confirm('¿Estas Seguro de Eliminar?',msj, { type: 'delete', btnConfirmText: 'Si, Eliminalo', btnCancelText: 'Descartar' });

        if(confirm){
          this.load_delete= true;
          const url = 'api/allergens/' + item.id;
          try{
            const {data} = await this.$api.delete(url);         
            this.load_delete= false;
            this.getalerganos();
            this.resetalergano();
            this.search = '';
            this.snackbar = {
              text: data.message,
              type: 'success',
              active: true            
            }
          }
          catch(error){
            this.load_delete= false;
            if(error.response.status == "400"){
              this.snackbar = {
                text: error.response.data.message,
                type: 'error',
                active: true            
              }    
            }
            else{
              this.snackbar = {
                text: 'Lo sentimos, hubo un error al intentar eliminar el Alérgeno.',
                type: 'error',
                active: true            
              }              
            }
          }
        }
      },
    },
  }
</script>
